import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import HomePage from './pages/home';
import './App.css';

function App() {
  return (
    <div className='App'>
      <HomePage />
    </div>
  );
}

export default App;
