import AppBarComponent from '../../components/appbar'
import CanvasComponent from '../../components/canvas'

export default function HomePage() {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <AppBarComponent />
      <div style={{ backgroundColor: '#000000', padding: 12 }}>
        <small style={{ color: '#FFFFFF' }}>This website is currently in development. Please check back soon.</small>
      </div>
      {/* <CanvasComponent /> */}
    </div>
  )
}
